import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { Category, SelectedProduct, State } from '../types';
import React, { useEffect, useState } from 'react';
import { checkmarkDone, checkmarkOutline, chevronDownOutline, chevronForwardOutline, close } from 'ionicons/icons';
import { setSelectedProducts } from '../redux/actions';
import { get, set } from '../helper/localStorage';

interface Props {
  selectedProducts: SelectedProduct[];
  setSelectedProducts: (ids: string[]) => void;
  disabled: boolean
}

const AddProduct = ({ selectedProducts, setSelectedProducts, disabled }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [addedProducts, setAddedProducts] = useState([] as string[]);
  const [openCategories, setOpenCategories] = useState(get('openCategories') as string[] || [])
  const [searchText, setSearchText] = useState('');

  const categories = get('categories') as Category[];

  const onCancel = () => {
    set('openCategories', openCategories);
    setSearchText('');
    setShowModal(false);
  }
  const toggleAddProduct = (id: string) => {
    if (addedProducts.includes(id)) {
      setAddedProducts(addedProducts.filter((item) => id !== item))
    } else {
      setAddedProducts([...addedProducts, id]);
    }
  }
  const toggleCategory = (name: string) => {
    if (openCategories.includes(name)) {
      setOpenCategories(openCategories.filter(category => name !== category));
    } else {
      setOpenCategories([...openCategories, name])
    }
  }
  const onModalClose = () => {
    setSelectedProducts(addedProducts);
    onCancel();
  }
  const includeSearch = (name: string) => name.toLowerCase().includes(searchText.toLowerCase())

  useEffect(() => {
    setAddedProducts(selectedProducts.map(p => p.id));
  }, [selectedProducts])

  return (<>
    <IonButton
      color="primary"
      disabled={isEmpty(categories) || disabled}
      onClick={() => setShowModal(true)}
    >
      Add/Remove
    </IonButton>
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>
            Add/Remove Entries
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchText}
            onIonChange={e => setSearchText(e.detail.value!)}
            placeholder="Search"
            showCancelButton="always"
            onIonCancel={onCancel}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {categories.map((category) => (
            <div key={category.name}>
              <IonItem
                detail={false}
                button
                color="light"
                onClick={() => toggleCategory(category.name)}
                className="sticky-top"
                lines="full">
                <IonLabel>
                  {category.name}
                </IonLabel>
                <IonIcon
                  color="medium"
                  icon={openCategories.includes(category.name) ? chevronDownOutline : chevronForwardOutline}
                  slot="end">
                </IonIcon>
              </IonItem>
              {openCategories.includes(category.name) && category.products.map((product) => (
                includeSearch(product.name) && <IonItem
                  lines="none"
                  detail={false}
                  key={product.id}
                  button onClick={() => toggleAddProduct(product.id)}>
                  <IonIcon className={addedProducts.includes(product.id) ? '' : 'hidden'} color="primary" icon={checkmarkOutline} slot="start"></IonIcon>
                  <IonLabel color={addedProducts.includes(product.id) ? "primary" : ""}>
                    {product.name}
                  </IonLabel>
                </IonItem>
              ))}
            </div>
          ))}
        </IonList>
      </IonContent>
      <IonFooter translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="medium"
              onClick={() => setAddedProducts([])}>
              Remove All
              <IonIcon icon={close} slot="start"></IonIcon>
            </IonButton>
            <IonButton
              color="medium"
              onClick={() => setAddedProducts(categories.map(c => c.products).flat().map(p => p.id))}
            >
              Add All
              <IonIcon icon={checkmarkDone} slot="start"></IonIcon>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => onModalClose()} color="success">
              <IonIcon icon={checkmarkOutline} slot="start"></IonIcon>
              Apply
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  </>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  setSelectedProducts: (ids: string[]) => dispatch(setSelectedProducts(ids)),
});
const mapStateToProps = (state: State) => ({
  selectedProducts: state.selectedProducts,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
