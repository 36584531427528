import {
  SET_PRODUCT_COUNT,
  REMOVE_ALL_PRODUCTS,
  TOGGLE_LOADING_STATE,
  RESET_ALL_PRODUCT_COUNT,
  SET_SELECTED_PRODUCTS,
  SET_CUSTOMER,
  SET_ONLINE,
  SET_SHOW_ERROR_ALERT,
  SET_PIN,
} from './actionTypes';

export const setSelectedProducts = (payload: string[]) => ({
  type: SET_SELECTED_PRODUCTS,
  payload,
})

export const removeAllProducts = () => ({
  type: REMOVE_ALL_PRODUCTS
});

export const setProductCount = (payload: { id: string, count: number }) => ({
  type: SET_PRODUCT_COUNT,
  payload,
});
export const setCustomer = (payload: string | null) => ({
  type: SET_CUSTOMER,
  payload,
});
export const resetAllProductCount = () => ({
  type: RESET_ALL_PRODUCT_COUNT,
});

export const toggleLoadingState = (payload: string) => ({
  type: TOGGLE_LOADING_STATE,
  payload,
})

export const setOnline = (payload: boolean) => ({
  type: SET_ONLINE,
  payload,
})

export const setShowErrorAlert = (payload: boolean) => ({
  type: SET_SHOW_ERROR_ALERT,
  payload,
})

export const setPin = (payload: string) => ({
  type: SET_PIN,
  payload,
})
