import { IonProgressBar } from '@ionic/react';
import { isEmpty } from 'ramda';
import { State } from '../types';
import { connect } from 'react-redux';

interface Props {
  loadingStates: string[],
}

const ProgressBar = ({ loadingStates }: Props) => {
  return <IonProgressBar
    className="absolute-position"
    type={isEmpty(loadingStates) ? 'determinate' : 'indeterminate'}
  ></IonProgressBar>
};

const mapStateToProps = (state: State) => ({
  loadingStates: state.loadingStates,
});

export default connect(mapStateToProps)(ProgressBar);
