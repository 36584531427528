import { IonToast } from '@ionic/react';
import React, { FC, useEffect } from 'react';


const UpdateAlert: FC = () => {
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker|null>(null);

  const handleNewServiceWorker = (event: any) => setWaitingWorker(event.detail.registration.waiting);

  useEffect(() => {
    document.addEventListener('onNewServiceWorker', handleNewServiceWorker);
      return () => document.removeEventListener('onNewServiceWorker', handleNewServiceWorker);
  });

  return (
    <IonToast
    isOpen={!!waitingWorker}
    message="New App Version Available!"
    position="bottom"
    buttons={[
      {
        text: 'Update',
        handler: () => {
          waitingWorker?.postMessage({type: 'SKIP_WAITING'})
        }
      }
    ]}
  />
  );
}

export default UpdateAlert;