import { IonItem, IonLabel, IonList, IonInput } from '@ionic/react';
import { compose, isEmpty, prop, sortBy, toLower } from 'ramda';
import { connect } from 'react-redux';
import { State, SelectedProduct, Category, ListProduct } from '../types';
import { setProductCount } from '../redux/actions';
import { get } from '../helper/localStorage';
import { useEffect, useState } from 'react';
import './ProductListItems.css';

interface Props {
  selectedProducts: SelectedProduct[];
  setProductCount: (id: string, count: number) => void;
}

const ProductListItems = ({ selectedProducts, setProductCount }: Props) => {
  const [products, setProducts] = useState([] as ListProduct[]);

  useEffect(() => {
    const allProducts = (get('categories') as Category[])
      .map((c) => c.products)
      .flat();
    const mapedProducts = selectedProducts.map((s) => ({
      ...s,
      name: allProducts.find((p) => p.id === s.id)?.name as string,
    }));
    const sortedProducts = sortBy(compose(toLower, prop('name') as any))(
      mapedProducts
    ) as ListProduct[];
    setProducts(sortedProducts);
  }, [selectedProducts]);

  return (
    <IonList class="ion-margin-bottom">
      {isEmpty(products) && (
        <IonItem lines="none">No products selected</IonItem>
      )}
      {products.map((product, index) => {
        return (
          <IonItem
            lines="none"
            key={product.id}
            color={index % 2 ? 'light' : ''}
          >
            <IonLabel class="no-max-width">{product.name}</IonLabel>
            <IonInput
              slot="end"
              class="quantity-input ion-text-end ion-padding-end ion-margin-end"
              inputMode="tel"
              min="0"
              type="tel"
              clearOnEdit
              value={product.count}
              onIonChange={(e) =>
                setProductCount(product.id as string, Number(e.detail.value))
              }
            ></IonInput>
          </IonItem>
        );
      })}
    </IonList>
  );
};

const mapStateToProps = (state: State) => ({
  selectedProducts: state.selectedProducts,
});
const mapDispatchToProps = (dispatch: any) => ({
  setProductCount: (id: string, count: number) =>
    dispatch(setProductCount({ id, count })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItems);
