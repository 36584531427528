import axios from 'axios';
import { SelectedProduct, Session } from '../types';
import { get, set } from './localStorage';

const apiCode = {
  code: process.env.REACT_APP_API_CODE,
};

const apiUri = `${process.env.REACT_APP_API_BASE_URI}/api`;

const session = () => get<string>('session');

export const getAuthUri = async () => {
  const { data } = await axios(`${apiUri}/AuthUri`, { params: apiCode });
  return data as string;
};

export const setSessionSec = (session: Session, pin: string) => {
  return axios(`${apiUri}/SetSessionSec`, { params: { ...apiCode, session: session.id, sec: session.sec, pin } });
}

export const checkSession = (sec: string) => {
  return axios(`${apiUri}/CheckSession`, { params: { ...apiCode, session: session(), sec } });
}

export const getMainData = async (sec: string) => {
  const { data } = await axios(`${apiUri}/GetMainData`, {
    params: { session: session(), ...apiCode, sec },
  });
  const today = (new Date()).toDateString();
  set('fetchedDataDate', today)
  set('companyName', data.companyName);
  set('customers', data.customers);
  set('categories', data.categories);
  return data;
}
export const submitDelivery = async (selectedProducts: SelectedProduct[], customer: string, signature: string, sec: string) => {
  selectedProducts = selectedProducts.filter(p => p.count > 0)
  const { data } = await axios.post(
    `${apiUri}/SubmitDelivery`,
    { selectedProducts, customer, signature },
    { params: { session: session(), ...apiCode, sec } }
  );
  return data;
}


