import { IonList } from '@ionic/react';
import ProductListHeader from './ProductListHeader';
import ProductListItems from './ProductListItems';

const ProductList = () => {
  return (
    <IonList>
      <ProductListHeader />
      <ProductListItems />
    </IonList>
  );
};

export default ProductList;
