import { createRef, RefObject } from 'react';
import { IonPopover, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import SignatureCanvas from 'react-signature-canvas';
import './SignPad.css';
import ReactSignatureCanvas from 'react-signature-canvas';

interface Props {
  signPadOpen: boolean;
  onSubmit: (signature: string) => void;
  onCancel: () => void;
}

const SignPad = ({ signPadOpen, onSubmit, onCancel }: Props) => {
  let sigCanvas: RefObject<ReactSignatureCanvas> = createRef();
  const submit = () => {
    if (!sigCanvas.current?.isEmpty()) {
      onSubmit(sigCanvas.current?.toDataURL() || '');
    }
  };
  const cancel = () => {
    sigCanvas.current?.clear();
    onCancel();
  };

  return (
    <>
      <IonPopover
        id="sign-pad"
        cssClass="sign-field"
        isOpen={signPadOpen}
        backdropDismiss={false}
        mode="md"
      >
        SIGNATURE
        <SignatureCanvas
          penColor="blue"
          backgroundColor="white"
          canvasProps={{
            width:
              (document.getElementById('ion-app')?.offsetWidth || 600) - 20,
            height: 200,
            className: 'sign-canvas',
          }}
          ref={sigCanvas}
        />
        <IonGrid>
          <IonRow class="ion-justify-content-between">
            <IonCol size="auto">
              <IonButton color="success" onClick={submit}>
                Submit
              </IonButton>
            </IonCol>
            <IonCol size="auto">
              <IonButton color="light" onClick={cancel}>
                Cancel
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonPopover>
    </>
  );
};

export default SignPad;
