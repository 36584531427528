import {
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { logOutOutline, refreshOutline } from 'ionicons/icons';
import './Header.css';
import brandSvg from '../assets/brand.svg';
import { State } from '../types';
import { connect } from 'react-redux';
import { clearFetchedData } from '../helper/localStorage';

interface Props {
  onLogout: () => void;
  sessionActive: boolean;
  online: boolean;
}

const Header = ({ onLogout, sessionActive, online }: Props) => {
  const refreshSession = () => {
    clearFetchedData();
    window.location.reload();
  };

  return (
    <IonToolbar>
      <IonTitle>
        <img className="brand" src={brandSvg} alt="DeliveryLink"></img>
      </IonTitle>
      {sessionActive && (
        <>
          <IonButtons slot="primary">
            <IonButton onClick={refreshSession} disabled={!online}>
              <IonIcon slot="icon-only" icon={refreshOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton onClick={onLogout}>
              <IonIcon slot="icon-only" icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </>
      )}
    </IonToolbar>
  );
};

const mapStateToProps = (state: State) => ({ online: state.online });

export default connect(mapStateToProps)(Header);
