import {
  SET_PRODUCT_COUNT,
  REMOVE_ALL_PRODUCTS,
  TOGGLE_LOADING_STATE,
  RESET_ALL_PRODUCT_COUNT,
  SET_SELECTED_PRODUCTS,
  SET_CUSTOMER,
  SET_ONLINE,
  SET_SHOW_ERROR_ALERT,
  SET_PIN,
} from './actionTypes';
import { clone } from 'ramda';
import { State } from '../types';
import { get, getSelectedProducts, set } from '../helper/localStorage';

const initialState: State = {
  selectedProducts: getSelectedProducts(),
  loadingStates: [] as string[],
  customer: get('selectedCustomer') as string | null,
  online: navigator.onLine,
  showErrorAlert: false,
  pin: '',
};

function rootReducer(state = initialState, action: any) {
  const clonedState = clone(state);
  switch (action.type) {
    case SET_SELECTED_PRODUCTS:
      const ids = action.payload as string[];
      set('selectedProducts', ids);
      const findProduct = (id: string) => state.selectedProducts.find(p => p.id === id);
      clonedState.selectedProducts = ids.map(id => ({ id, count: findProduct(id)?.count ?? 0 }));;
      break;
    case REMOVE_ALL_PRODUCTS:
      clonedState.selectedProducts = [];
      set('selectedProducts', []);
      break;
    case SET_PRODUCT_COUNT:
      clonedState.selectedProducts.forEach(p => {
        if (p.id === action.payload.id) p.count = action.payload.count
      });
      break;
    case RESET_ALL_PRODUCT_COUNT:
      clonedState.selectedProducts.forEach(p => {
        p.count = 0;
      });
      break;
    case SET_CUSTOMER:
      clonedState.customer = action.payload;
      break;
    case TOGGLE_LOADING_STATE:
      const isActive = clonedState.loadingStates.includes(action.payload);
      if (isActive) {
        clonedState.loadingStates = clonedState.loadingStates.filter(s => s !== action.payload);
      } else {
        clonedState.loadingStates.push(action.payload)
      }
      break;
    case SET_ONLINE:
      clonedState.online = action.payload;
      break;
    case SET_SHOW_ERROR_ALERT:
      clonedState.showErrorAlert = action.payload;
      break;
    case SET_PIN:
      clonedState.pin = action.payload;
      break;
  }
  return clonedState;
}

export default rootReducer;
