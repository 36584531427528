import { IonLabel, IonItem, IonIcon } from '@ionic/react';
import './CompanyName.css';
import { businessOutline } from 'ionicons/icons';
import { get } from '../helper/localStorage';

const CompanyName = () => {
  return (
    <IonItem lines="none">
      <IonIcon className="item-icon" icon={businessOutline} />
      <IonLabel>
        Supplier:
        <div className="company-name">{get('companyName')}</div>
      </IonLabel>
    </IonItem>
  );
};

export default CompanyName;
