import { IonListHeader, IonItem, IonText } from '@ionic/react';
import './ProductListHeader.css';

const ProductListHeader = () => {
  return (
    <IonListHeader className="sticky-top ion-no-padding">
      <IonItem className="full-width" lines="full" color="light">
        <IonText>
          <h5>Delivery</h5>
        </IonText>
        <IonText slot="end">
          <h5>Quantity</h5>
        </IonText>
      </IonItem>
    </IonListHeader>
  );
};

export default ProductListHeader;
