import { useEffect, useState } from 'react';
import { IonLabel, IonItem, IonIcon, IonModal, IonContent, IonList, IonSearchbar, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import { connect } from 'react-redux';
import { toggleLoadingState, setCustomer as setCustomerState } from '../redux/actions';
import { checkmarkOutline, peopleOutline } from 'ionicons/icons';
import { get, set } from '../helper/localStorage';
import { Customer } from '../types';

interface Props {
  toggleLoadingState: (context: string) => void;
  setCustomerState: (value: string) => void;
}

const CustomerSelect = ({ toggleLoadingState, setCustomerState }: Props) => {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>();
  const [customers, setCustomers] = useState<Customer[]>();
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const onCancel = () => {
    setSearchText('');
    setShowModal(false);
  }
  const onCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
    set('selectedCustomer', customer.id);
    setCustomerState(customer.id);
    onCancel();
  };

  useEffect(() => {
    toggleLoadingState('customer')
    const customers = get('customers') as Customer[];
    setCustomers(customers.filter(c => c.name.toLowerCase().includes(searchText.toLowerCase())))
    setSelectedCustomer(customers?.find(c => c.id === get('selectedCustomer')));
    toggleLoadingState('customer')
  }, [toggleLoadingState, searchText]);

  return (<>
    <IonItem lines="full" onClick={() => setShowModal(true)} button>
      <IonIcon className='item-icon' icon={peopleOutline} />
      <IonLabel>Customer:</IonLabel>
      <IonLabel className="ion-text-end" color={!selectedCustomer ? 'medium' : ''}>
        {selectedCustomer?.name || "Select Customer"}
      </IonLabel>
    </IonItem>
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>
            Select Customer
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchText}
            onIonChange={e => setSearchText(e.detail.value!)}
            placeholder="Search"
            showCancelButton="always"
            onIonCancel={onCancel}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonList>
          {customers?.map((customer) => (
            <IonItem lines="none" detail={false} key={customer.id} button onClick={() => onCustomerSelect(customer)}>
              <IonLabel color={customer === selectedCustomer ? "primary" : ""}>
                {customer.name}
              </IonLabel>
              <IonIcon color="primary" icon={customer === selectedCustomer ? checkmarkOutline : ''} slot="start"></IonIcon>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoadingState: (context: string) => dispatch(toggleLoadingState(context)),
  setCustomerState: (value: string) => dispatch(setCustomerState(value)),
});

export default connect(null, mapDispatchToProps)(CustomerSelect);
