import { IonGrid, IonRow, IonCol } from '@ionic/react';
import AddProduct from './AddProduct';
import SubmitButton from './SubmitButton';
import { connect } from 'react-redux';
import { State } from '../types';
import { isEmpty } from 'ramda';

interface Props {
  loadingStates: string[]
}

const ActionButtons = ({ loadingStates }: Props) => {
  const disabled = !isEmpty(loadingStates);
  return (
    <IonGrid className="sticky-bottom">
      <IonRow class="ion-justify-content-between">
        <IonCol size="auto">
          <AddProduct disabled={disabled} />
        </IonCol>
        <IonCol size="auto">
          <SubmitButton disabled={disabled} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const mapStateToProps = (state: State) => ({ loadingStates: state.loadingStates });

export default connect(mapStateToProps)(ActionButtons);
