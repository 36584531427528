import { IonAlert, IonButton, IonLoading } from '@ionic/react';
import { useState } from 'react';
import { State, SelectedProduct } from '../types';
import { connect } from 'react-redux';
import { sum } from 'ramda';
import { resetAllProductCount, setShowErrorAlert } from '../redux/actions';
import SignPad from './SignPad';
import { submitDelivery } from '../helper/api';

interface Props {
  selectedProducts: SelectedProduct[];
  disabled: boolean;
  customer: string | null;
  online: boolean;
  pin: string;
  resetAllProductCount: () => void;
  showErrorAlert: () => void;
}

const SubmitButton = ({
  selectedProducts,
  disabled,
  customer,
  online,
  pin,
  resetAllProductCount,
  showErrorAlert,
}: Props) => {
  const [showAlert, setShowSuccessAlert] = useState(false);
  const [showSubmitLoading, setSubmitLoading] = useState(false);
  const [signPadOpen, setSignPadOpen] = useState(false);
  const submit = async (signature: string) => {
    setSignPadOpen(false);
    setSubmitLoading(true);
    try {
      await submitDelivery(selectedProducts, customer!, signature, pin);
      setShowSuccessAlert(true);
    } catch {
      showErrorAlert();
    } finally {
      setSubmitLoading(false);
    }
  };
  const noAmount = sum(selectedProducts.map(p => p.count)) === 0;
  return (
    <>
      <IonButton
        color="success"
        disabled={disabled || noAmount || !customer || !online}
        onClick={() => setSignPadOpen(true)}
      >
        Sign & Submit
      </IonButton>
      <SignPad
        signPadOpen={signPadOpen}
        onSubmit={submit}
        onCancel={() => setSignPadOpen(false)}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowSuccessAlert(false);
          resetAllProductCount();
        }
        }
        subHeader={'Successfully Processed'}
        buttons={['OK']} />
      <IonLoading
        isOpen={showSubmitLoading}
        message={'Sending...'}
      />
    </>
  );
};

const mapStateToProps = (state: State) => ({
  selectedProducts: state.selectedProducts,
  customer: state.customer,
  online: state.online,
  pin: state.pin,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetAllProductCount: () => dispatch(resetAllProductCount()),
  showErrorAlert: () => dispatch(setShowErrorAlert(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton);
