import { useState, useCallback, useEffect } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import './Index.css';
import ConnectQuickBooks from '../components/ConnectQuickBooks';
import MainContent from '../components/MainContent';
import Header from '../components/Header';
import { Session, State } from '../types';
import { connect } from 'react-redux';
import { get } from '../helper/localStorage';
import SetPinCard from '../components/SetPinCard';
import UnlockModal from '../components/UnlockModal';
import ErrorAlert from '../components/ErrorAlert';
import ProgressBar from '../components/ProgressBar';

interface Props {
  pinStore: string;
}

const Index = ({ pinStore }: Props) => {
  const [sessionActive, setSessionActive] = useState(!!get('session'));
  const [session, setSession] = useState(null as null | Session);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showConnectButton, setShowConnectButton] = useState(!sessionActive);

  const onConnected = useCallback((session: Session) => {
    setSession(session);
    setShowConnectButton(false);
  }, []);
  const onPinSet = (success: boolean) => {
    setSessionActive(success);
  };
  const logout = (cleanup: boolean) => {
    setSessionActive(false);
    if (cleanup) {
      localStorage.clear();
    } else {
      localStorage.removeItem('session');
    }
    window.location.reload();
  };

  useEffect(() => {
    setShowUnlockModal(sessionActive && !pinStore);
  }, [sessionActive, pinStore]);

  return (
    <IonPage>
      <IonHeader>
        <Header onLogout={() => logout(true)} sessionActive={sessionActive} />
        <ProgressBar />
      </IonHeader>
      <IonContent fullscreen={true}>
        {session && !sessionActive && (
          <SetPinCard session={session} onPinSet={onPinSet} />
        )}
        {sessionActive
          ? !showUnlockModal && <MainContent />
          : showConnectButton && (
            <ConnectQuickBooks onConnected={onConnected} />
          )}
      </IonContent>
      <UnlockModal isOpen={showUnlockModal} onReconnect={() => logout(false)} />
      <ErrorAlert />
    </IonPage>
  );
};

const mapStateToProps = (state: State) => ({
  pinStore: state.pin,
});

export default connect(mapStateToProps)(Index);
