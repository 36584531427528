import { IonAlert } from "@ionic/react"
import { connect } from "react-redux";
import { setShowErrorAlert } from "../redux/actions";
import { State } from "../types";

interface Props {
  showErrorAlert: boolean
  setShowErrorAlert: (state: boolean) => void;
}

const ErrorAlert = ({ showErrorAlert, setShowErrorAlert }: Props) => {
  return <IonAlert
    isOpen={showErrorAlert}
    onDidDismiss={() => setShowErrorAlert(false)}
    header="Error"
    subHeader={'Please try again later.'}
    message={'Contact the support, if it doesn\'t work again.'}
    buttons={['OK']}
  />
}

const mapStateToProps = (state: State) => ({
  showErrorAlert: state.showErrorAlert,
});

const mapDispatchToProps = (dispatch: any) => ({
  setShowErrorAlert: (context: boolean) => dispatch(setShowErrorAlert(context)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
