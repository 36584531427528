import { IonButton, IonCard, IonCardContent, IonIcon, IonInput, IonItem, IonText } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { setPin as setPinStore, setShowErrorAlert } from '../redux/actions';

import { connect } from "react-redux";
import { set } from "../helper/localStorage";
import { checkmark, lockClosedOutline } from "ionicons/icons";
import { Session } from "../types";
import { setSessionSec } from "../helper/api";

interface Props {
  setPinStore: (value: string) => void;
  onPinSet: (success: boolean) => void;
  showErrorAlert: () => void;
  session: Session;
}

const SetPinCard = ({ setPinStore, onPinSet, session, showErrorAlert }: Props) => {
  const [pin, setPin] = useState('');

  const onSubmit = async () => {
    try {
      await setSessionSec(session, pin)
      set('session', session.id);
      setPinStore(pin);
      onPinSet(true);
    } catch (error) {
      showErrorAlert();
      onPinSet(false);
    }
  }
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.setFocus(), 500);
  })

  return <IonCard className="ion-text-center">
    <IonCardContent>
      <IonText color="dark">
        <br />
        <h1><IonIcon icon={lockClosedOutline}></IonIcon></h1>
        <br />
        <h1>Set a session PIN</h1>
        <br />
        <p>The PIN secures your connection to QuickBooks.</p>
        <br />
      </IonText>
      <IonItem className="ion-text-center" color="light" lines="none">
        <IonInput
          ref={(ref) => inputRef.current = ref}
          type="tel"
          inputmode="tel"
          value={pin}
          placeholder="Enter 4 Digits here"
          onIonChange={e => setPin(e.detail.value!)}
          minlength={4}
          maxlength={4}
          pattern="\d{4}"
        >
        </IonInput>
      </IonItem>
      <IonButton disabled={pin.length !== 4} className="ion-margin-top" color="primary" onClick={onSubmit}>Ok<IonIcon icon={checkmark} slot="start" /></IonButton>
    </IonCardContent>
  </IonCard>
};

const mapDispatchToProps = (dispatch: any) => ({
  setPinStore: (value: string) => dispatch(setPinStore(value)),
  showErrorAlert: () => dispatch(setShowErrorAlert(true)),
})

export default connect(null, mapDispatchToProps)(SetPinCard);