import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import React from 'react';
import UpdateAlert from './components/UpdateAlert';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
    <UpdateAlert />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();

serviceWorker.register({ onUpdate: (registration: ServiceWorkerRegistration) => {
  const event = new CustomEvent('onNewServiceWorker', { detail: { registration } });
  document.dispatchEvent(event)
  registration.waiting?.addEventListener('statechange', (event: any) => {
    if (event.target.state === 'activated') {
      window.location.reload();
    }
  });
} });

