export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL_PRODUCTS';
export const SET_PRODUCT_COUNT = 'SET_PRODUCT_COUNT';
export const SET_FETCHED_PRODUCTS = 'SET_FETCHED_PRODUCTS';
export const TOGGLE_LOADING_STATE = 'TOGGLE_LOADING_STATE';
export const RESET_ALL_PRODUCT_COUNT = 'RESET_ALL_PRODUCT_COUNT';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_ONLINE = 'SET_ONLINE';
export const SET_SHOW_ERROR_ALERT = 'SET_SHOW_ERROR_ALERT';
export const SET_PIN = 'SET_PIN';
