import CustomerSelect from './CustomerSelect';
import ProductList from './ProductList';
import ActionButtons from './ActionButtons';
import CompanyName from './CompanyName';
import { getMainData } from '../helper/api';
import { useEffect, useState } from 'react';
import { setSelectedProducts, setShowErrorAlert, toggleLoadingState } from '../redux/actions';
import { connect } from 'react-redux';
import { get, getSelectedProducts } from '../helper/localStorage';
import { Category, State } from '../types';
import { IonLoading } from '@ionic/react';

interface Props {
  toggleLoadingState: (context: string) => void;
  setSelectedProducts: (ids: string[]) => void;
  online: boolean;
  pin: string;
  showErrorAlert: () => void;
}

const MainContent = ({ toggleLoadingState, setSelectedProducts, online, pin, showErrorAlert }: Props) => {
  const [display, setDisplay] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const fetched = get('fetchedDataDate') as string;
    if (online && pin && !fetched) {
      toggleLoadingState('mainData');
      setShowLoading(true);
      getMainData(pin)
        .then(() => {
          const productIds = (get('categories') as Category[]).flat().map(c => c.products).flat().map(p => p.id);
          const selectedProducts = getSelectedProducts().filter(p => productIds.includes(p.id)).map(p => p.id);
          setSelectedProducts(selectedProducts);
          setDisplay(true);
        }).catch(() => showErrorAlert())
        .finally(() => {
          toggleLoadingState('mainData');
          setShowLoading(false);
        });;
    } else if (fetched) {
      setDisplay(true);
    }

  }, [setDisplay, toggleLoadingState, setSelectedProducts, online, pin, showErrorAlert]);

  return <div>
    {display && <>
      <CompanyName />
      <CustomerSelect />
      <ProductList />
      <ActionButtons />
    </>}
    <IonLoading
      isOpen={showLoading}
      message={'Fetching Quickbooks Data'}
      duration={60000}
      onDidDismiss={() => setShowLoading(false)}
    />
  </div>
};

const mapStateToProps = (state: State) => ({
  online: state.online,
  pin: state.pin,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoadingState: (context: string) => dispatch(toggleLoadingState(context)),
  setSelectedProducts: (ids: string[]) => dispatch(setSelectedProducts(ids)),
  showErrorAlert: () => dispatch(setShowErrorAlert(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);