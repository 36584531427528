export const get = <T>(key: string) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value!) as T;
  } catch (error) {
    return value;
  }
};

export const set = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

export const isSet = (key: string) => !!localStorage.getItem(key);

export const clearFetchedData = () => {
  ['companyName', 'customers', 'products', 'fetchedDataDate'].forEach(key => localStorage.removeItem(key));
}

export const getSelectedProducts = () => {
  const ids = get('selectedProducts') as string[] || [];
  return ids.map(id => ({ id, count: 0 }));
};