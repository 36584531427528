import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonModal, IonProgressBar, IonText } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { lockClosedOutline } from "ionicons/icons";
import { setPin as setPinStore } from '../redux/actions';
import { connect } from "react-redux";
import { checkSession } from "../helper/api";


interface Props {
  setPinStore: (pin: string) => void;
  onReconnect: () => void;
  isOpen: boolean;
}

const UnlockModal = ({ setPinStore, onReconnect, isOpen }: Props) => {
  const [pin, setPin] = useState('');
  const [attemps, setAttempts] = useState(0);
  const [checking, setChecking] = useState(false);

  const onPinEnter = async (pin: string) => {
    setPin(pin);
    if (pin.length === 4) {
      try {
        setChecking(true);
        await checkSession(pin);
        setPinStore(pin);
        setPin('');
      } catch (error) {
        const statusCode = error.response?.status as number;
        if (statusCode === 403) {
          setAttempts(attemps + 1);
        }
        setPin('');
        inputRef.current?.setFocus();
      } finally {
        setChecking(false);
      }
    }
  }
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.setFocus(), 500);
  });

  return <IonModal isOpen={isOpen} backdropDismiss={false} animated={false}>
    <IonContent className="ion-text-center" fullscreen={true}>
      <IonText>
        <br />
        <h1><IonIcon icon={lockClosedOutline}></IonIcon></h1>
        <h1>Session Locked</h1>
        <p>Please enter the session PIN to unlock:</p>
      </IonText>
      <IonItem className="ion-text-center " color="light" lines="none">
        <IonInput
          ref={(ref) => inputRef.current = ref}
          type="tel"
          inputmode="tel"
          placeholder="Type PIN here"
          value={pin}
          onIonChange={e => onPinEnter(e.detail.value!)}
          minlength={4}
          maxlength={4}
          pattern="\d{4}"
          disabled={checking || attemps === 3}
        >
        </IonInput>
      </IonItem>
      <IonProgressBar
        className="ion-margin-bottom"
        type={checking ? 'indeterminate' : 'determinate'}
      >
      </IonProgressBar>
      <div className="fixed-text-height">
        <IonText color="danger">
          {attemps > 0 && attemps < 3 && <span>PIN not valid. {3 - attemps} {attemps === 2 ? 'attempt' : 'attempts'} left.</span>}
          {attemps === 3 && <span>PIN still not valid. Session closed, please reconnect.</span>}
        </IonText>
      </div>
      <IonButton className="ion-margin-top" fill="outline" color="danger" onClick={onReconnect}>Reconnect</IonButton>
    </IonContent>
  </IonModal>;
};

const mapDispatchToProps = (dispatch: any) => ({
  setPinStore: (pin: string) => dispatch(setPinStore(pin))
})

export default connect(null, mapDispatchToProps)(UnlockModal);