import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Index from './pages/Index';
import Terms from './pages/Terms';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';
import { connect } from 'react-redux';
import { setOnline } from './redux/actions';

interface Props {
  setOnline: (state: boolean) => void;
}

const App = ({ setOnline }: Props) => {
  useEffect(() => {
    window.addEventListener('online', (e) => setOnline(true));
    window.addEventListener('offline', (e) => setOnline(false));
    return () => {
      window.removeEventListener('online', (e) => setOnline(true));
      window.removeEventListener('offline', (e) => setOnline(false));
    };
  });

  return (
    <IonApp id="ion-app">
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/" component={Index} exact={true} />
          <Route path="/terms" component={Terms} exact={true} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setOnline: (state: boolean) => dispatch(setOnline(state)),
});

export default connect(null, mapDispatchToProps)(App);
