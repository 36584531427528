import { useState, useEffect } from 'react';
import './ConnectQuickBookButton.css';
import { getAuthUri } from '../helper/api';
import { connect } from 'react-redux';
import { setShowErrorAlert, toggleLoadingState } from '../redux/actions';
import { Session } from '../types';
import { IonContent, IonText } from '@ionic/react';

interface Props {
  onConnected: (session: Session) => void;
  toggleLoadingState: (context: string) => void;
  showErrorAlert: () => void;
}

const ConnectQuickBooks = ({
  onConnected,
  toggleLoadingState,
  showErrorAlert,
}: Props) => {
  const [authUri, setAuthUri] = useState('');
  const [error, setError] = useState(false);
  const openOAuth = () => window.open(authUri);

  const eventListener = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE_URI) {
      const session = event.data as Session;
      if (!session.id) {
        setError(true);
        return;
      }
      onConnected(session);
    }
  };
  const addListener = () =>
    window.addEventListener('message', eventListener, true);
  const removeListener = () =>
    window.removeEventListener('message', eventListener, true);

  useEffect(() => {
    if (!authUri) {
      toggleLoadingState('connect-url');
      getAuthUri()
        .then((uri) => {
          setAuthUri(uri);
        })
        .catch(() => showErrorAlert())
        .finally(() => toggleLoadingState('connect-url'));
    }
    addListener();
    return () => {
      removeListener();
    };
  });

  return (
    <>
      {authUri ? <div className="qb-connect" onClick={openOAuth} /> : <div />}
      {error && (
        <IonContent className="ion-text-center">
          <IonText color="danger">
            Connection invalid. Please contact the support.
          </IonText>
        </IonContent>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoadingState: (context: string) =>
    dispatch(toggleLoadingState(context)),
  showErrorAlert: () => dispatch(setShowErrorAlert(true)),
});

export default connect(null, mapDispatchToProps)(ConnectQuickBooks);
